<script>
// eslint-disable-next-line no-unused-vars
import TablePage from '../../../../../components/table_page';
// eslint-disable-next-line no-unused-vars
import request from '../../../../../utils/request';

export default {
  name: 'table-component',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/sfa/sfaSignFormsController/exceptionReportDetail',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('sfa_attendance_abnormal_report_details');
  },
};
</script>
